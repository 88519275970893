import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

const billingInformationPost_Body = z
  .object({
    billingAddress: z
      .object({
        cep: z.string(),
        city: z.string(),
        neighborhood: z.string(),
        state: z.string(),
        street: z.string(),
        streetNumber: z.string(),
      })
      .passthrough(),
    billingPhone: z
      .object({ ddd: z.string(), number: z.string() })
      .passthrough(),
    companyName: z.string().nullish(),
    dob: z.string().nullish(),
    documentNumber: z.string(),
    documentType: z.string(),
    email: z.string(),
    name: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_VariationOption = z
  .object({
    name: z.string(),
    pcopID: z.number(),
    povaID: z.number(),
    price: z.number(),
    priceMode: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation =
  z
    .object({
      caprID: z.number(),
      license: z.array(z.string()),
      price: z.number(),
      prodID: z.string(),
      sku: z.string(),
      slug: z.string(),
      thumbnailImageUrl: z.string(),
      title: z.string(),
      variations: z.array(
        github_com_bamboo_stock_ecommerce_backend_service_VariationOption
      ),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation =
  z
    .object({
      cartID: z.string(),
      code: z.string(),
      priceRules: z
        .object({
          discountAmount: z.string().nullable(),
          discountPercentage: z.string().nullable(),
        })
        .partial()
        .passthrough(),
      voucID: z.number(),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ManagerCartView = z
  .object({
    cartID: z.string(),
    cartProducts: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
    ),
    cartVouchers: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
    ),
    checkout: z
      .object({
        checID: z.number(),
        methodName: z.string(),
        paymentStatus: z.string(),
        price: z.number(),
      })
      .passthrough()
      .nullish(),
    createdAt: z.string(),
    customer: z.object({ name: z.string(), userID: z.string() }).passthrough(),
    manaID: z.string(),
    updatedAt: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CartItem = z
  .object({ prodID: z.string(), variations: z.array(z.number()) })
  .passthrough();
const cartCreate_Body = z
  .object({
    cartItems: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_CartItem
    ),
    session: z.array(z.number()).nullish(),
    userID: z.string().nullish(),
  })
  .passthrough();
const cartVoucherDelete_Body = z
  .object({ cartID: z.string(), voucher: z.string() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Category = z
  .object({
    cateID: z.string(),
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    ordination: z.number().nullish(),
    parentID: z.string().nullish(),
    slug: z.string(),
    thumbnail: z.string().nullish(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_NewCategory = z
  .object({
    cateID: z.string().nullish(),
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    ordination: z.number().nullish(),
    parentID: z.string().nullish(),
    slug: z.string().nullish(),
    thumbnail: z.string().nullish(),
  })
  .passthrough();
const categoryCreate_Body = z
  .object({
    categories: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_NewCategory
    ),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ClipCategory = z
  .object({
    cateID: z.number(),
    description: z.string(),
    name: z.string(),
    order: z.number(),
    slug: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist = z
  .object({
    description: z.string(),
    name: z.string(),
    plliID: z.number(),
    slug: z.string(),
    sortOrder: z.number(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Tag = z
  .object({ name: z.string(), tagID: z.number() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Clip = z
  .object({
    categories: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ClipCategory
    ),
    createdAt: z.string(),
    description: z.string(),
    filmID: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    license: z.array(z.string()),
    makerAvatar: z.string(),
    makerDisplayName: z.string(),
    makerName: z.string(),
    makerSlug: z.string(),
    playlists: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist
    ),
    price: z.number(),
    prinID: z.number(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    tags: z.array(github_com_bamboo_stock_ecommerce_backend_service_Tag),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Variation = z
  .object({
    displaySettings: z.object({ widget: z.string() }).passthrough(),
    name: z.string(),
    options: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_VariationOption
    ),
    prcoID: z.number(),
    required: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Collection = z
  .object({
    collID: z.number(),
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    parentID: z.number().nullish(),
    shortDescription: z.string(),
    slug: z.string(),
    sortOrder: z.number().nullish(),
    thumbnail: z.string().nullish(),
    visible: z.boolean(),
  })
  .passthrough();
const collectionCreate_Body = z
  .object({
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    shortDescription: z.string(),
    slug: z.string(),
    sortOrder: z.number().nullish(),
    thumbnail: z.string().nullish(),
    visible: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductDownloadLink = z
  .object({
    downID: z.number(),
    downloadableUrl: z.string(),
    name: z.string(),
    povaID: z.number(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_DownloadableClip = z
  .object({
    categories: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ClipCategory
    ),
    createdAt: z.string(),
    description: z.string(),
    downloadLinks: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ProductDownloadLink
    ),
    filmID: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    license: z.array(z.string()),
    makerAvatar: z.string(),
    makerDisplayName: z.string(),
    makerName: z.string(),
    makerSlug: z.string(),
    playlists: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist
    ),
    price: z.number(),
    prinID: z.number(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    tags: z.array(github_com_bamboo_stock_ecommerce_backend_service_Tag),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FilmmakerApplication = z
  .object({
    clientID: z.string(),
    createdAt: z.string(),
    email: z.string(),
    fiapID: z.number(),
    name: z.string(),
    nationality: z.string(),
    phone: z.string(),
    showcase: z.string(),
    socialMedia: z.string(),
    state: z.string(),
    surname: z.string(),
    website: z.string(),
  })
  .passthrough();
const filmmakerProductOrderCreateOrder_Body = z
  .object({ prinID: z.number(), sortOrder: z.number() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductOrder =
  z
    .object({ filmID: z.string(), prinID: z.number(), sortOrder: z.number() })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_AdminFilmmakerInfo = z
  .object({
    avatar: z.string().nullish(),
    description: z.string(),
    displayName: z.string(),
    email: z.string().nullish(),
    filmID: z.string(),
    headerImage: z.string().nullish(),
    location: z.string(),
    name: z.string(),
    slug: z.string(),
    tags: z.array(z.string()),
    userID: z.string(),
    visibility: z.boolean(),
  })
  .passthrough();
const filmmakerCreate_Body = z
  .object({
    description: z.string(),
    displayName: z.string(),
    slug: z.string().nullish(),
    tags: z.array(z.string()),
    userID: z.string(),
    visibility: z.boolean().nullish(),
  })
  .passthrough();
const filmmakerPatch_Body = z
  .object({
    avatar: z.string().nullable(),
    description: z.string().nullable(),
    displayName: z.string().nullable(),
    headerImage: z.string().nullable(),
    slug: z.string().nullable(),
    tags: z.array(z.string()).nullable(),
    visibility: z.boolean().nullable(),
  })
  .partial()
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_DashboardAmountLicense =
  z
    .object({ amountSold: z.number(), soldAt: z.string().nullish() })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_JobApplication = z
  .object({
    city: z.string(),
    clientID: z.string(),
    createdAt: z.string(),
    cv: z.string(),
    email: z.string(),
    joapID: z.number(),
    name: z.string(),
    phone: z.string(),
    socialMedia: z.string(),
    state: z.string(),
    surname: z.string(),
  })
  .passthrough();
const managerCreate_Body = z
  .object({ roles: z.array(z.string()), userID: z.string() })
  .passthrough();
const marketplacerecipientCreate_Body = z
  .object({
    bankAccount: z.string(),
    bankAccountDigit: z.string(),
    bankAccountHolderName: z.string(),
    bankAgency: z.string(),
    bankAgencyDigit: z.string(),
    bankCode: z.string(),
    bankDocumentNumber: z.string(),
    bankDocumentType: z.string(),
    bankType: z.string(),
    pixKey: z.string().nullish(),
    transferDay: z.number(),
    transferInterval: z.string(),
  })
  .passthrough();
const marketplaceuserPost_Body = z
  .object({
    address: z
      .object({
        address: z.string(),
        city: z.string(),
        complement: z.string(),
        zipCode: z.string(),
      })
      .passthrough(),
    documentNumber: z.string(),
    documentType: z.string(),
    holderName: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_NewslettersRegistration =
  z
    .object({
      clientID: z.string(),
      createdAt: z.string(),
      email: z.string(),
      nereID: z.number(),
    })
    .passthrough();
const oneTimeLoginCreate_Body = z
  .object({ userID: z.string(), validUntil: z.string() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Playlist = z
  .object({
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    parentID: z.number().nullish(),
    plliID: z.number(),
    shortDescription: z.string(),
    slug: z.string(),
    sortOrder: z.number().nullish(),
    thumbnail: z.string().nullish(),
    visible: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Authorization = z
  .object({ name: z.string(), releaseType: z.string(), url: z.string() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation =
  z
    .object({
      configurationName: z.string(),
      deletedAt: z.string().nullish(),
      name: z.string(),
      pcopID: z.number(),
      povaID: z.number(),
      prcoID: z.number(),
      price: z.number(),
      priceMode: z.string(),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_AdminProductListing = z
  .object({
    approval: z
      .object({
        apprID: z.string(),
        approved: z.boolean(),
        createdAt: z.string(),
        prinID: z.number(),
        reason: z.string(),
        status: z.string(),
      })
      .passthrough()
      .nullish(),
    filmmaker: z
      .object({
        avatar: z.string().nullish(),
        description: z.string(),
        displayName: z.string(),
        filmID: z.string(),
      })
      .passthrough()
      .nullish(),
    product: z
      .object({
        address: z
          .object({
            address: z.string().nullable(),
            city: z.string().nullable(),
            country: z.string().nullable(),
            country_short: z.string().nullable(),
            lat: z.number().nullable(),
            lng: z.number().nullable(),
            name: z.string().nullable(),
            place_id: z.string().nullable(),
            state: z.string().nullable(),
            state_short: z.string().nullable(),
          })
          .partial()
          .passthrough(),
        authorizations: z.array(
          github_com_bamboo_stock_ecommerce_backend_service_Authorization
        ),
        categories: z.array(z.number()),
        createdAt: z.string(),
        description: z.string().nullish(),
        hdUrl: z.string(),
        information: z
          .object({
            codecName: z.string(),
            duration: z.number(),
            framerate: z.string(),
            height: z.number(),
            width: z.number(),
          })
          .passthrough(),
        license: z.array(z.string()),
        pendingMedia: z.boolean(),
        pendingReview: z.boolean(),
        price: z.number().nullish(),
        prinID: z.number().nullish(),
        prodID: z.string().nullish(),
        sku: z.string().nullish(),
        slug: z.string().nullish(),
        tagNames: z.array(z.string()),
        thumbnailImageUrl: z.string(),
        thumbnailVideoUrl: z.string(),
        title: z.string().nullish(),
        ultrahd4kUrl: z.string(),
        updatedAt: z.string(),
        upmeID: z.string(),
        variations: z.array(
          github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
        ),
        visible: z.boolean(),
        watermarkPreviewUrl: z.string(),
      })
      .passthrough(),
  })
  .passthrough();
const productCreate_Body = z
  .object({
    address: z
      .object({
        address: z.string().nullable(),
        city: z.string().nullable(),
        country: z.string().nullable(),
        country_short: z.string().nullable(),
        lat: z.number().nullable(),
        lng: z.number().nullable(),
        name: z.string().nullable(),
        place_id: z.string().nullable(),
        state: z.string().nullable(),
        state_short: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    authorizations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_Authorization
    ),
    categories: z.array(z.number()),
    description: z.string(),
    filmID: z.string(),
    license: z.array(z.string()),
    tagNames: z.array(z.string()),
    title: z.string(),
    upmeID: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_NewProductCollection = z
  .object({ prinID: z.number(), sortOrder: z.number() })
  .passthrough();
const productCollectionCreate_Body = z
  .object({
    products: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_NewProductCollection
    ),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductCollection = z
  .object({ collID: z.number(), prinID: z.number(), sortOrder: z.number() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductConfiguration = z
  .object({
    displaySettings: z.object({ widget: z.string() }).passthrough(),
    name: z.string(),
    prcoID: z.number(),
    required: z.boolean(),
  })
  .passthrough();
const productConfigurationCreate_Body = z
  .object({
    displaySettings: z.object({ widget: z.string() }).passthrough(),
    name: z.string(),
    required: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductConfigurationOption =
  z
    .object({
      name: z.string(),
      pcopID: z.number(),
      prcoID: z.number(),
      price: z.number(),
      priceMode: z.string(),
    })
    .passthrough();
const productConfigurationOptionCreate_Body = z
  .object({
    name: z.string(),
    prcoID: z.number(),
    price: z.number(),
    priceMode: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductPlaylist = z
  .object({ plliID: z.number(), prinID: z.number(), sortOrder: z.number() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_NewProductPlaylist = z
  .object({ prinID: z.number(), sortOrder: z.number() })
  .passthrough();
const productPlaylistCreate_Body = z
  .object({
    products: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_NewProductPlaylist
    ),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_UpdateProductVariation =
  z
    .object({ pcopID: z.number(), price: z.number(), priceMode: z.string() })
    .passthrough();
const productUpdatePrice_Body = z
  .object({
    price: z.number(),
    variations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_UpdateProductVariation
    ),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateSettingsScheme =
  z.object({ editable: z.boolean(), pcopID: z.number() }).passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateView = z
  .object({
    price: z.number(),
    prteID: z.string(),
    settings: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateSettingsScheme
    ),
  })
  .passthrough();
const productTemplateUpdate_Body = z
  .object({
    applyToProducts: z.boolean(),
    price: z.number(),
    settings: z.array(z.number()),
  })
  .passthrough();
const productCreateApproval_Body = z
  .object({
    approved: z.boolean().nullish(),
    prinID: z.number(),
    reason: z.string().nullish(),
  })
  .passthrough();
const notificationSendReviewEmail_Body = z
  .object({
    apprIDs: z.array(z.string()),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductReview = z
  .object({
    address: z
      .object({
        address: z.string().nullable(),
        city: z.string().nullable(),
        country: z.string().nullable(),
        country_short: z.string().nullable(),
        lat: z.number().nullable(),
        lng: z.number().nullable(),
        name: z.string().nullable(),
        place_id: z.string().nullable(),
        state: z.string().nullable(),
        state_short: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    apprID: z.string(),
    authorizations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_Authorization
    ),
    categories: z.array(z.number()),
    description: z.string().nullish(),
    license: z.array(z.string()),
    price: z.number(),
    prinID: z.number().nullish(),
    prodID: z.string().nullish(),
    slug: z.string(),
    tagNames: z.array(z.string()),
    title: z.string().nullish(),
    variations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
    ),
    visible: z.boolean(),
  })
  .passthrough();
const productUpdateReview_Body = z
  .object({
    address: z
      .object({
        address: z.string().nullable(),
        city: z.string().nullable(),
        country: z.string().nullable(),
        country_short: z.string().nullable(),
        lat: z.number().nullable(),
        lng: z.number().nullable(),
        name: z.string().nullable(),
        place_id: z.string().nullable(),
        state: z.string().nullable(),
        state_short: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    approval: z
      .object({ approved: z.boolean(), reason: z.string() })
      .passthrough(),
    authorizations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_Authorization
    ),
    categories: z.array(z.number()),
    description: z.string(),
    filmID: z.string(),
    license: z.array(z.string()),
    slug: z.string(),
    tagNames: z.array(z.string()),
    title: z.string(),
    visible: z.boolean(),
  })
  .passthrough();
const productUpdate_Body = z
  .object({
    address: z
      .object({
        address: z.string().nullable(),
        city: z.string().nullable(),
        country: z.string().nullable(),
        country_short: z.string().nullable(),
        lat: z.number().nullable(),
        lng: z.number().nullable(),
        name: z.string().nullable(),
        place_id: z.string().nullable(),
        state: z.string().nullable(),
        state_short: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    authorizations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_Authorization
    ),
    categories: z.array(z.number()),
    description: z.string(),
    filmID: z.string(),
    license: z.array(z.string()),
    slug: z.string(),
    tagNames: z.array(z.string()),
    title: z.string(),
    visible: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CheckoutProduct = z
  .object({
    filmID: z.string(),
    license: z.array(z.string()),
    makerName: z.string(),
    makerSlug: z.string(),
    methodName: z.string(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    thumbnailImageURL: z.string(),
    title: z.string(),
    value: z.number(),
    variations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_VariationOption
    ),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CheckoutVoucher = z
  .object({ code: z.string(), value: z.number(), voucID: z.number() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Sale = z
  .object({
    cartID: z.string(),
    checID: z.number(),
    companyName: z.string(),
    plan: z.string().nullish(),
    createdAt: z.string(),
    customer: z
      .object({
        biinID: z.string().nullish(),
        billingAddress: z
          .object({
            cep: z.string(),
            city: z.string(),
            neighborhood: z.string(),
            state: z.string(),
            street: z.string(),
            streetNumber: z.string(),
          })
          .passthrough()
          .nullish(),
        billingPhone: z
          .object({ ddd: z.string(), number: z.string() })
          .passthrough()
          .nullish(),
        documentNumber: z.string().nullish(),
        documentType: z.string().nullish(),
        email: z.string(),
        name: z.string(),
      })
      .passthrough()
      .nullish(),
    invoID: z.string(),
    methodCode: z.string().nullish(),
    methodName: z.string().nullish(),
    methodURL: z.string().nullish(),
    orderOfService: z.string(),
    paymentStatus: z.string().nullish(),
    products: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_CheckoutProduct
    ),
    projectName: z.string(),
    state: z.string(),
    status: z.string(),
    transactionID: z.string(),
    userID: z.string().nullish(),
    value: z.number(),
    vouchers: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_CheckoutVoucher
    ),
  })
  .passthrough();
const salesActons_Body = z
  .object({
    action: z.string(),
    enableDownloadables: z.boolean().nullish(),
    refund: z.boolean().nullish(),
    status: z.string().nullish(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Term = z
  .object({
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    description: z.string(),
    slug: z.string(),
    termID: z.number(),
    title: z.string(),
    type: z.string(),
  })
  .passthrough();
const termCreate_Body = z
  .object({
    description: z.string(),
    slug: z.string(),
    title: z.string(),
    type: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_UploadedMedia = z
  .object({
    deletedAt: z.string().nullish(),
    hdURL: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    ultraHd4kURL: z.string(),
    uploadedAt: z.string(),
    uploadedBY: z.string(),
    upmeID: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const uploadedMediaCreate_Body = z
  .object({
    hdURL: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    ultraHd4kURL: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const downloadablesUpdate_Body = z
  .object({
    downloadCount: z.number().nullable(),
    maxDownload: z.number().nullable(),
  })
  .partial()
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation =
  z
    .object({
      configurationName: z.string(),
      optionName: z.string(),
      povaID: z.number(),
      prcoID: z.number(),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_UserDownloadableView = z
  .object({
    checID: z.number(),
    createdAt: z.string(),
    downID: z.number(),
    downloadCount: z.number(),
    downloadUrl: z.string().nullish(),
    maxDownload: z.number(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    usdoID: z.number(),
    userID: z.string(),
    variations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation
    ),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_UserInformation = z
  .object({
    applID: z.string(),
    authenticationProvider: z.string(),
    avatar: z.string().nullish(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    email: z.string().nullish(),
    filmID: z.string().nullish(),
    info: z.object({}).partial().passthrough(),
    name: z.string(),
    pushTokens: z.array(z.string()).nullish(),
    roles: z.array(z.string()),
    userID: z.string(),
  })
  .passthrough();
const userCreate_Body = z
  .object({
    applID: z.string(),
    email: z.string(),
    name: z.string(),
    password: z.string().nullish(),
  })
  .passthrough();
const userUpdate_Body = z
  .object({
    avatar: z.string().nullable(),
    email: z.string().nullable(),
    name: z.string().nullable(),
  })
  .partial()
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Voucher = z
  .object({
    code: z.string(),
    createdAt: z.string(),
    deletedAt: z.string().nullish(),
    expiredAt: z.string(),
    priceRules: z
      .object({
        discountAmount: z.string().nullable(),
        discountPercentage: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    startedAt: z.string(),
    usageRules: z
      .object({
        amountOfUse: z.number(),
        amountOfUsePerUser: z.number(),
        onlyInCart: z.boolean(),
      })
      .passthrough(),
    voucID: z.number(),
  })
  .passthrough();
const voucherCreate_Body = z
  .object({
    code: z.string(),
    expiredAt: z.string(),
    priceRules: z
      .object({
        discountAmount: z.string().nullable(),
        discountPercentage: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    startedAt: z.string(),
    usageRules: z
      .object({
        amountOfUse: z.number(),
        amountOfUsePerUser: z.number(),
        onlyInCart: z.boolean(),
      })
      .passthrough(),
  })
  .passthrough();
const voucherUpdate_Body = z
  .object({
    expiredAt: z.string(),
    priceRules: z
      .object({
        discountAmount: z.string().nullable(),
        discountPercentage: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    startedAt: z.string(),
    usageRules: z
      .object({
        amountOfUse: z.number(),
        amountOfUsePerUser: z.number(),
        onlyInCart: z.boolean(),
      })
      .passthrough(),
  })
  .passthrough();

export const schemas = {
  billingInformationPost_Body,
  github_com_bamboo_stock_ecommerce_backend_service_VariationOption,
  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation,
  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation,
  github_com_bamboo_stock_ecommerce_backend_service_ManagerCartView,
  github_com_bamboo_stock_ecommerce_backend_service_CartItem,
  cartCreate_Body,
  cartVoucherDelete_Body,
  github_com_bamboo_stock_ecommerce_backend_service_Category,
  github_com_bamboo_stock_ecommerce_backend_service_NewCategory,
  categoryCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ClipCategory,
  github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist,
  github_com_bamboo_stock_ecommerce_backend_service_Tag,
  github_com_bamboo_stock_ecommerce_backend_service_Clip,
  github_com_bamboo_stock_ecommerce_backend_service_Variation,
  github_com_bamboo_stock_ecommerce_backend_service_Collection,
  collectionCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ProductDownloadLink,
  github_com_bamboo_stock_ecommerce_backend_service_DownloadableClip,
  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerApplication,
  filmmakerProductOrderCreateOrder_Body,
  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductOrder,
  github_com_bamboo_stock_ecommerce_backend_service_AdminFilmmakerInfo,
  filmmakerCreate_Body,
  filmmakerPatch_Body,
  github_com_bamboo_stock_ecommerce_backend_service_DashboardAmountLicense,
  github_com_bamboo_stock_ecommerce_backend_service_JobApplication,
  managerCreate_Body,
  marketplacerecipientCreate_Body,
  marketplaceuserPost_Body,
  github_com_bamboo_stock_ecommerce_backend_service_NewslettersRegistration,
  oneTimeLoginCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_Playlist,
  github_com_bamboo_stock_ecommerce_backend_service_Authorization,
  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation,
  github_com_bamboo_stock_ecommerce_backend_service_AdminProductListing,
  productCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_NewProductCollection,
  productCollectionCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ProductCollection,
  github_com_bamboo_stock_ecommerce_backend_service_ProductConfiguration,
  productConfigurationCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ProductConfigurationOption,
  productConfigurationOptionCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ProductPlaylist,
  github_com_bamboo_stock_ecommerce_backend_service_NewProductPlaylist,
  productPlaylistCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_UpdateProductVariation,
  productUpdatePrice_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateSettingsScheme,
  github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateView,
  productTemplateUpdate_Body,
  productCreateApproval_Body,
  github_com_bamboo_stock_ecommerce_backend_service_ProductReview,
  productUpdateReview_Body,
  productUpdate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_CheckoutProduct,
  github_com_bamboo_stock_ecommerce_backend_service_CheckoutVoucher,
  github_com_bamboo_stock_ecommerce_backend_service_Sale,
  salesActons_Body,
  github_com_bamboo_stock_ecommerce_backend_service_Term,
  termCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_UploadedMedia,
  uploadedMediaCreate_Body,
  downloadablesUpdate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation,
  github_com_bamboo_stock_ecommerce_backend_service_UserDownloadableView,
  github_com_bamboo_stock_ecommerce_backend_service_UserInformation,
  userCreate_Body,
  userUpdate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_Voucher,
  voucherCreate_Body,
  voucherUpdate_Body,
};

const endpoints = makeApi([
  {
    method: 'post',
    path: '/api/auth-media',
    alias: 'mediaAuth',
    description: `Authenticate media server access`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z
          .object({ mediaURL: z.string().nullable() })
          .partial()
          .passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ jwt: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/billing-information/:userID',
    alias: 'billingInformationGet',
    description: `Get one admin billing information`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                biinID: z.string(),
                billingAddress: z
                  .object({
                    cep: z.string(),
                    city: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                  })
                  .passthrough(),
                billingPhone: z
                  .object({ ddd: z.string(), number: z.string() })
                  .passthrough(),
                companyName: z.string().nullish(),
                createdAt: z.string(),
                createdBy: z.string(),
                dob: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                email: z.string(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/billing-information/edit/:userID',
    alias: 'billingInformationPost',
    description: `Post billing information`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: billingInformationPost_Body,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                biinID: z.string(),
                billingAddress: z
                  .object({
                    cep: z.string(),
                    city: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                  })
                  .passthrough(),
                billingPhone: z
                  .object({ ddd: z.string(), number: z.string() })
                  .passthrough(),
                companyName: z.string().nullish(),
                createdAt: z.string(),
                createdBy: z.string(),
                dob: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                email: z.string(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/cart',
    alias: 'cartList',
    description: `Lists all carts created by the manager`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'customerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'checID',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ManagerCartView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart',
    alias: 'cartCreate',
    description: `Create a new cart to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                checkout: z
                  .object({
                    checID: z.number(),
                    methodName: z.string(),
                    paymentStatus: z.string(),
                    price: z.number(),
                  })
                  .passthrough()
                  .nullish(),
                createdAt: z.string(),
                customer: z
                  .object({ name: z.string(), userID: z.string() })
                  .passthrough(),
                manaID: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/cart-voucher',
    alias: 'cartVoucherDelete',
    description: `Remove the voucher from the cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartVoucherDelete_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ cartID: z.string(), voucID: z.number() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart-voucher',
    alias: 'cartVoucherCreate',
    description: `validates and links a voucher to the cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartVoucherDelete_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                code: z.string(),
                priceRules: z
                  .object({
                    discountAmount: z.string().nullable(),
                    discountPercentage: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                voucID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/cart/:cartID',
    alias: 'cartDeleteItem',
    description: `Remove product to cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ caprID: z.number() }).passthrough(),
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                checkout: z
                  .object({
                    checID: z.number(),
                    methodName: z.string(),
                    paymentStatus: z.string(),
                    price: z.number(),
                  })
                  .passthrough()
                  .nullish(),
                createdAt: z.string(),
                customer: z
                  .object({ name: z.string(), userID: z.string() })
                  .passthrough(),
                manaID: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/cart/:cartID',
    alias: 'cartGet',
    description: `Get cart by cartID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                checkout: z
                  .object({
                    checID: z.number(),
                    methodName: z.string(),
                    paymentStatus: z.string(),
                    price: z.number(),
                  })
                  .passthrough()
                  .nullish(),
                createdAt: z.string(),
                customer: z
                  .object({ name: z.string(), userID: z.string() })
                  .passthrough(),
                manaID: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart/:cartID',
    alias: 'cartCreateItem',
    description: `Add product to cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartCreate_Body,
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                checkout: z
                  .object({
                    checID: z.number(),
                    methodName: z.string(),
                    paymentStatus: z.string(),
                    price: z.number(),
                  })
                  .passthrough()
                  .nullish(),
                createdAt: z.string(),
                customer: z
                  .object({ name: z.string(), userID: z.string() })
                  .passthrough(),
                manaID: z.string(),
                updatedAt: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/categories',
    alias: 'categoryList',
    description: `List all categories to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'slug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'parentSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'notParentSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'parentID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Category
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/categories',
    alias: 'categoryCreate',
    description: `Create new categories`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: categoryCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Category
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/clips',
    alias: 'clipsListClips',
    description: `List all clips to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'makerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'playlistName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'searchText',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[desc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'afterID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'playlistSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'cateID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'categoryName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'categorySlug',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'collectionSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'excludeProdIDs',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'plliID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'order[asc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'collID',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Clip
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/clips/:slug/variations',
    alias: 'clipsGetClipVariations',
    description: `Get clip variations by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                price: z.number(),
                prodID: z.string(),
                slug: z.string(),
                variations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_Variation
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/collection',
    alias: 'collectionList',
    description: `Lists all collections`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'shortDescription',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'description',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'draft',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'visible',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Collection
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/collection',
    alias: 'collectionCreate',
    description: `Create a playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: collectionCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/collection/:collID',
    alias: 'collectionDelete',
    description: `Delete one collection by id`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/collection/:collID',
    alias: 'collectionGet',
    description: `Get one collection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/collection/:collID',
    alias: 'collectionPut',
    description: `Update a collection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: collectionCreate_Body,
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/collection/:collID/draft',
    alias: 'collectionGetDraft',
    description: `Get draft collection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/collection/:collID/draft',
    alias: 'collectionCreateDraft',
    description: `Create a draft collection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: collectionCreate_Body,
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/collection/:collID/draft',
    alias: 'collectionApplyDraft',
    description: `Apply draft collection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/favorite-product/clips/:userID',
    alias: 'favoriteProductListClips',
    description: `List all favorite clips by userID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'afterID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[asc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[desc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_DownloadableClip
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmaker-applications',
    alias: 'filmmakerApplicationList',
    description: `Lists all filmmaker application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'email',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_FilmmakerApplication
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/filmmaker-applications/:fiapID',
    alias: 'filmmakerApplicationDelete',
    description: `Delete one filmmaker application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'fiapID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                clientID: z.string(),
                createdAt: z.string(),
                email: z.string(),
                fiapID: z.number(),
                name: z.string(),
                nationality: z.string(),
                phone: z.string(),
                showcase: z.string(),
                socialMedia: z.string(),
                state: z.string(),
                surname: z.string(),
                website: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/filmmaker-product-order/:filmID/sort-order',
    alias: 'filmmakerProductOrderCreateOrder',
    description: `Create new filmmaker product order`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: filmmakerProductOrderCreateOrder_Body,
      },
      {
        name: 'filmID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductOrder
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmakers',
    alias: 'filmmakerList',
    description: `Lists all filmmakers`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'tag',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_AdminFilmmakerInfo
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/filmmakers',
    alias: 'filmmakerCreate',
    description: `Create one filmmaker`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: filmmakerCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                avatar: z.string().nullish(),
                description: z.string(),
                displayName: z.string(),
                email: z.string().nullish(),
                filmID: z.string(),
                headerImage: z.string().nullish(),
                location: z.string(),
                name: z.string(),
                slug: z.string(),
                tags: z.array(z.string()),
                userID: z.string(),
                visibility: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmakers/:filmID',
    alias: 'filmmakerGet',
    description: `Get one filmmaker`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'filmID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                avatar: z.string().nullish(),
                description: z.string(),
                displayName: z.string(),
                email: z.string().nullish(),
                filmID: z.string(),
                headerImage: z.string().nullish(),
                location: z.string(),
                name: z.string(),
                slug: z.string(),
                tags: z.array(z.string()),
                userID: z.string(),
                visibility: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'patch',
    path: '/api/filmmakers/:filmID',
    alias: 'filmmakerPatch',
    description: `Patch one filmmaker`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: filmmakerPatch_Body,
      },
      {
        name: 'filmID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                avatar: z.string().nullish(),
                description: z.string(),
                displayName: z.string(),
                email: z.string().nullish(),
                filmID: z.string(),
                headerImage: z.string().nullish(),
                location: z.string(),
                name: z.string(),
                slug: z.string(),
                tags: z.array(z.string()),
                userID: z.string(),
                visibility: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmakers/:filmID/dashboard',
    alias: 'filmmakerDashboard',
    description: `Get filmmaker dashboard`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'filmID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                filmID: z.string(),
                listDailySale: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_DashboardAmountLicense
                ),
                totalSales: z.number(),
                totalValue: z.number(),
                totalViews: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/job-applications',
    alias: 'jobApplicationList',
    description: `Lists all job application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_JobApplication
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/job-applications/:joapID',
    alias: 'jobApplicationDelete',
    description: `job one job application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'joapID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                city: z.string(),
                clientID: z.string(),
                createdAt: z.string(),
                cv: z.string(),
                email: z.string(),
                joapID: z.number(),
                name: z.string(),
                phone: z.string(),
                socialMedia: z.string(),
                state: z.string(),
                surname: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/manager',
    alias: 'managerCreate',
    description: `Create new user manager`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: managerCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                manaID: z.string(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/manager',
    alias: 'managerUpdate',
    description: `Update one user manager`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: managerCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                manaID: z.string(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/marketplace/payments/:paymID/refund',
    alias: 'marketplacePaymentRefund',
    description: `Refund marketplace payment`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'paymID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                invoID: z.string(),
                methodCode: z.string(),
                methodName: z.string(),
                methodURL: z.string(),
                paymID: z.string(),
                status: z.string(),
                userID: z.string(),
                value: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/marketplace/recipient/:filmID',
    alias: 'marketplacerecipientGet',
    description: `Get one marketplacerecipient`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'filmID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bankAccount: z.string(),
                bankAccountDigit: z.string(),
                bankAccountHolderName: z.string(),
                bankAgency: z.string(),
                bankAgencyDigit: z.string(),
                bankCode: z.string(),
                bankDocumentNumber: z.string(),
                bankDocumentType: z.string(),
                bankType: z.string(),
                createdAt: z.string(),
                createdBy: z.string(),
                deletedAt: z.string().nullish(),
                filmID: z.string(),
                mareID: z.string(),
                method: z.string(),
                methodID: z.string(),
                pixKey: z.string().nullish(),
                transferDay: z.number(),
                transferInterval: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/marketplace/recipient/:filmID',
    alias: 'marketplacerecipientCreate',
    description: `Create or update one marketplacerecipient`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: marketplacerecipientCreate_Body,
      },
      {
        name: 'filmID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bankAccount: z.string(),
                bankAccountDigit: z.string(),
                bankAccountHolderName: z.string(),
                bankAgency: z.string(),
                bankAgencyDigit: z.string(),
                bankCode: z.string(),
                bankDocumentNumber: z.string(),
                bankDocumentType: z.string(),
                bankType: z.string(),
                createdAt: z.string(),
                createdBy: z.string(),
                deletedAt: z.string().nullish(),
                filmID: z.string(),
                mareID: z.string(),
                method: z.string(),
                methodID: z.string(),
                pixKey: z.string().nullish(),
                transferDay: z.number(),
                transferInterval: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/marketplace/user/:userID',
    alias: 'marketplaceuserGet',
    description: `Get one marketplaceuser`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    address: z.string(),
                    city: z.string(),
                    complement: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough(),
                createdAt: z.string(),
                documentNumber: z.string(),
                documentType: z.string(),
                holderName: z.string(),
                mausID: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/marketplace/user/:userID',
    alias: 'marketplaceuserPost',
    description: `Create or update one marketplaceuser`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: marketplaceuserPost_Body,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    address: z.string(),
                    city: z.string(),
                    complement: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough(),
                createdAt: z.string(),
                documentNumber: z.string(),
                documentType: z.string(),
                holderName: z.string(),
                mausID: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/newsletters-registration',
    alias: 'newslettersRegistrationList',
    description: `Lists all newsletters registration`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_NewslettersRegistration
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/newsletters-registration/:nereID',
    alias: 'newslettersRegistrationDelete',
    description: `Delete one newsletters registration`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'nereID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                clientID: z.string(),
                createdAt: z.string(),
                email: z.string(),
                nereID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/one-time-login',
    alias: 'oneTimeLoginCreate',
    description: `Create new oneTimeLogin`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: oneTimeLoginCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                otloID: z.number(),
                token: z.string(),
                userID: z.string(),
                validUntil: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/playlist',
    alias: 'playlistList',
    description: `Lists all playlists for admin`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'shortDescription',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'description',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'draft',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'visible',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Playlist
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/playlist',
    alias: 'playlistCreate',
    description: `Create a playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: collectionCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/playlist/:plliID',
    alias: 'playlistDelete',
    description: `Delete one playlist by id`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/playlist/:plliID',
    alias: 'playlistGet',
    description: `Get a playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/playlist/:plliID',
    alias: 'playlistPut',
    description: `Update a playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: collectionCreate_Body,
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/playlist/:plliID/draft',
    alias: 'playlistGetDraft',
    description: `Get draft playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/playlist/:plliID/draft',
    alias: 'playlistCreateDraft',
    description: `Create a draft playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: collectionCreate_Body,
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/playlist/:plliID/draft',
    alias: 'playlistApplyDraft',
    description: `Apply draft playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product',
    alias: 'productList',
    description: `Lists all products`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'sku',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'ReviewStatus',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'updatedAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagName',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'pendingReview',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'makerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'upmeID',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'approved',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'pendingMedia',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'approvalStatus',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'title',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'prodID',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'uploadedBy',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'visible',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'updatedAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'license',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'categories',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_AdminProductListing
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product',
    alias: 'productCreate',
    description: `Create one product`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                filmmaker: z
                  .object({
                    avatar: z.string().nullish(),
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    pendingReview: z.boolean(),
                    price: z.number().nullish(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    sku: z.string().nullish(),
                    slug: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultrahd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    variations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                    ),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product-collection/:collID',
    alias: 'productCollectionCreate',
    description: `Create product collection`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productCollectionCreate_Body,
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductCollection
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/product-collection/:collID/:prinID',
    alias: 'productCollectionDelete',
    description: `Delete one product collection by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'prinID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                prinID: z.number(),
                sortOrder: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product-collection/:collID/sort-order',
    alias: 'productCollectionCreateOrder',
    description: `Create new product collection order`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: filmmakerProductOrderCreateOrder_Body,
      },
      {
        name: 'collID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductCollection
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-configuration',
    alias: 'productConfigurationList',
    description: `Lists all product configurations`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductConfiguration
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product-configuration',
    alias: 'productConfigurationCreate',
    description: `Create product configuration`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productConfigurationCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                displaySettings: z.object({ widget: z.string() }).passthrough(),
                name: z.string(),
                prcoID: z.number(),
                required: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-configuration-option',
    alias: 'productConfigurationOptionList',
    description: `Lists all product configuration options`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductConfigurationOption
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product-configuration-option',
    alias: 'productConfigurationOptionCreate',
    description: `Create product configuration option`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productConfigurationOptionCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                name: z.string(),
                pcopID: z.number(),
                prcoID: z.number(),
                price: z.number(),
                priceMode: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-configuration-option/:pcopID',
    alias: 'productConfigurationOptionGet',
    description: `Get one product configuration options`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'pcopID',
        type: 'Path',
        schema: z.number(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                name: z.string(),
                pcopID: z.number(),
                prcoID: z.number(),
                price: z.number(),
                priceMode: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product-configuration-option/:pcopID',
    alias: 'productConfigurationOptionUpdate',
    description: `Update one product configuration option`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productConfigurationOptionCreate_Body,
      },
      {
        name: 'pcopID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                name: z.string(),
                pcopID: z.number(),
                prcoID: z.number(),
                price: z.number(),
                priceMode: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-configuration/:prcoID',
    alias: 'productConfigurationGet',
    description: `Get one product configuration`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'prcoID',
        type: 'Path',
        schema: z.number(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                displaySettings: z.object({ widget: z.string() }).passthrough(),
                name: z.string(),
                prcoID: z.number(),
                required: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product-configuration/:prcoID',
    alias: 'productConfigurationUpdate',
    description: `Update one product configuration`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productConfigurationCreate_Body,
      },
      {
        name: 'prcoID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                displaySettings: z.object({ widget: z.string() }).passthrough(),
                name: z.string(),
                prcoID: z.number(),
                required: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-playlist/:plliID',
    alias: 'productPlaylistGet',
    description: `Get product playlist by plliID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductPlaylist
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product-playlist/:plliID',
    alias: 'productPlaylistCreate',
    description: `Create product playlist`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productPlaylistCreate_Body,
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductPlaylist
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/product-playlist/:plliID/:prinID',
    alias: 'productPlaylistDelete',
    description: `Delete one product playlist by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'prinID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                plliID: z.number(),
                prinID: z.number(),
                sortOrder: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product-playlist/:plliID/sort-order',
    alias: 'productPlaylistCreateOrder',
    description: `Create new product playlist order`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: filmmakerProductOrderCreateOrder_Body,
      },
      {
        name: 'plliID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductPlaylist
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product-price/:prinID',
    alias: 'productUpdatePrice',
    description: `Update product price`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productUpdatePrice_Body,
      },
      {
        name: 'prinID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                filmmaker: z
                  .object({
                    avatar: z.string().nullish(),
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    pendingReview: z.boolean(),
                    price: z.number().nullish(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    sku: z.string().nullish(),
                    slug: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultrahd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    variations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                    ),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-template',
    alias: 'productTemplateList',
    description: `Lists all product templates`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product-template/:prteID',
    alias: 'productTemplateGet',
    description: `Get one product template`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'prteID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                price: z.number(),
                prteID: z.string(),
                settings: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateSettingsScheme
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product-template/:prteID',
    alias: 'productTemplateUpdate',
    description: `Update one product template`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productTemplateUpdate_Body,
      },
      {
        name: 'prteID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                price: z.number(),
                prteID: z.string(),
                settings: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_ProductTemplateSettingsScheme
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/product/:prodID',
    alias: 'productRemove',
    description: `Remove one product by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                filmmaker: z
                  .object({
                    avatar: z.string().nullish(),
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    pendingReview: z.boolean(),
                    price: z.number().nullish(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    sku: z.string().nullish(),
                    slug: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultrahd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    variations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                    ),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product/:prodID',
    alias: 'productGet',
    description: `Get one product by prodID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                filmmaker: z
                  .object({
                    avatar: z.string().nullish(),
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    pendingReview: z.boolean(),
                    price: z.number().nullish(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    sku: z.string().nullish(),
                    slug: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultrahd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    variations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                    ),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product/:prodID',
    alias: 'productUpdate',
    description: `Update one product informations`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productUpdate_Body,
      },
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                filmmaker: z
                  .object({
                    avatar: z.string().nullish(),
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    pendingReview: z.boolean(),
                    price: z.number().nullish(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    sku: z.string().nullish(),
                    slug: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultrahd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    variations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                    ),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product/approval',
    alias: 'productCreateApproval',
    description: `Create a new approval to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productCreateApproval_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                apprID: z.string(),
                approved: z.boolean(),
                createdAt: z.string(),
                prinID: z.number(),
                reason: z.string(),
                status: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product/review/notification',
    alias: 'notificationSendApprovalEmail',
    description: `Send email notification of product approval`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: notificationSendReviewEmail_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product/review',
    alias: 'productListReview',
    description: `Lists all product information updates`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'prodID',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ProductReview
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product/review/:prinID',
    alias: 'productUpdateReview',
    description: `Update one product informations`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productUpdateReview_Body,
      },
      {
        name: 'prinID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    address: z.string().nullable(),
                    city: z.string().nullable(),
                    country: z.string().nullable(),
                    country_short: z.string().nullable(),
                    lat: z.number().nullable(),
                    lng: z.number().nullable(),
                    name: z.string().nullable(),
                    place_id: z.string().nullable(),
                    state: z.string().nullable(),
                    state_short: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                apprID: z.string(),
                authorizations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_Authorization
                ),
                categories: z.array(z.number()),
                description: z.string().nullish(),
                license: z.array(z.string()),
                price: z.number(),
                prinID: z.number().nullish(),
                prodID: z.string().nullish(),
                slug: z.string(),
                tagNames: z.array(z.string()),
                title: z.string().nullish(),
                variations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                ),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product/review/:prodID',
    alias: 'productGetReview',
    description: `Get product information updates`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    address: z.string().nullable(),
                    city: z.string().nullable(),
                    country: z.string().nullable(),
                    country_short: z.string().nullable(),
                    lat: z.number().nullable(),
                    lng: z.number().nullable(),
                    name: z.string().nullable(),
                    place_id: z.string().nullable(),
                    state: z.string().nullable(),
                    state_short: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                apprID: z.string(),
                authorizations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_Authorization
                ),
                categories: z.array(z.number()),
                description: z.string().nullish(),
                license: z.array(z.string()),
                price: z.number(),
                prinID: z.number().nullish(),
                prodID: z.string().nullish(),
                slug: z.string(),
                tagNames: z.array(z.string()),
                title: z.string().nullish(),
                variations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                ),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/sales',
    alias: 'salesList',
    description: `Lists all sales`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'state',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'checID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'methodName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'paymentStatus',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'paymID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'customerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Sale
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/sales/:checID',
    alias: 'salesGet',
    description: `Get one sale by checID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'checID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                checID: z.number(),
                companyName: z.string(),
                createdAt: z.string(),
                customer: z
                  .object({
                    biinID: z.string().nullish(),
                    billingAddress: z
                      .object({
                        cep: z.string(),
                        city: z.string(),
                        neighborhood: z.string(),
                        state: z.string(),
                        street: z.string(),
                        streetNumber: z.string(),
                      })
                      .passthrough()
                      .nullish(),
                    billingPhone: z
                      .object({ ddd: z.string(), number: z.string() })
                      .passthrough()
                      .nullish(),
                    documentNumber: z.string().nullish(),
                    documentType: z.string().nullish(),
                    email: z.string(),
                    name: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                invoID: z.string(),
                methodCode: z.string().nullish(),
                methodName: z.string().nullish(),
                methodURL: z.string().nullish(),
                orderOfService: z.string(),
                plan: z.string().nullish(),
                paymentStatus: z.string().nullish(),
                products: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CheckoutProduct
                ),
                projectName: z.string(),
                state: z.string(),
                status: z.string(),
                transactionID: z.string(),
                userID: z.string().nullish(),
                value: z.number(),
                vouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CheckoutVoucher
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/sales/:checID/actions',
    alias: 'salesActons',
    description: `perform actions on a sale`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: salesActons_Body,
      },
      {
        name: 'checID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                checID: z.number(),
                companyName: z.string(),
                createdAt: z.string(),
                customer: z
                  .object({
                    biinID: z.string().nullish(),
                    billingAddress: z
                      .object({
                        cep: z.string(),
                        city: z.string(),
                        neighborhood: z.string(),
                        state: z.string(),
                        street: z.string(),
                        streetNumber: z.string(),
                      })
                      .passthrough()
                      .nullish(),
                    billingPhone: z
                      .object({ ddd: z.string(), number: z.string() })
                      .passthrough()
                      .nullish(),
                    documentNumber: z.string().nullish(),
                    documentType: z.string().nullish(),
                    email: z.string(),
                    name: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                invoID: z.string(),
                methodCode: z.string().nullish(),
                methodName: z.string().nullish(),
                methodURL: z.string().nullish(),
                orderOfService: z.string(),
                paymentStatus: z.string().nullish(),
                products: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CheckoutProduct
                ),
                projectName: z.string(),
                state: z.string(),
                status: z.string(),
                transactionID: z.string(),
                userID: z.string().nullish(),
                value: z.number(),
                vouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CheckoutVoucher
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/term',
    alias: 'termList',
    description: `Lists all terms`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'title',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'slug',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Term
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/term',
    alias: 'termCreate',
    description: `Create one term`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: termCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/term/:termID',
    alias: 'termDelete',
    description: `Delete one term by id`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'termID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/term/:termID',
    alias: 'termGet',
    description: `Get one term`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'termID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/term/:termID',
    alias: 'termUpdate',
    description: `Update term`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: termCreate_Body,
      },
      {
        name: 'termID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/uploaded-media',
    alias: 'uploadedMediaList',
    description: `List all uploaded video to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'linkedToProduct',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'uploadedByApplID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'uploadedBy',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'uploadedAt[Gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'uploadedAt[Lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_UploadedMedia
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/uploaded-media',
    alias: 'uploadedMediaCreate',
    description: `Create a new uploaded video to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: uploadedMediaCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                deletedAt: z.string().nullish(),
                hdURL: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                ultraHd4kURL: z.string(),
                uploadedAt: z.string(),
                uploadedBY: z.string(),
                upmeID: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/uploaded-media/:upmeID',
    alias: 'uploadedMediaRemove',
    description: `Remove one uploaded video by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'upmeID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                deletedAt: z.string().nullish(),
                hdURL: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                ultraHd4kURL: z.string(),
                uploadedAt: z.string(),
                uploadedBY: z.string(),
                upmeID: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/uploaded-media/:upmeID',
    alias: 'uploadedMediaGet',
    description: `Get one uploaded video by id to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'upmeID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                deletedAt: z.string().nullish(),
                hdURL: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                ultraHd4kURL: z.string(),
                uploadedAt: z.string(),
                uploadedBY: z.string(),
                upmeID: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/uploaded-products',
    alias: 'uploadedProductsList',
    description: `Lists all products uploaded by admin`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'visible',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'title',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'upmeID',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'pendingMedia',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'updatedAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'approved',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'tagName',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'approvalStatus',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'license',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'categories',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'uploadedByApplID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'uploadedBy',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'updatedAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_AdminProductListing
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/uploaded-products/:upmeID',
    alias: 'uploadedProductsGet',
    description: `Get one product uploaded by admin`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'upmeID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                filmmaker: z
                  .object({
                    avatar: z.string().nullish(),
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    pendingReview: z.boolean(),
                    price: z.number().nullish(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    sku: z.string().nullish(),
                    slug: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultrahd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    variations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductVariation
                    ),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'patch',
    path: '/api/user-downloadables/:usdoID',
    alias: 'downloadablesUpdate',
    description: `Update one user downloadable`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: downloadablesUpdate_Body,
      },
      {
        name: 'usdoID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                checID: z.number(),
                createdAt: z.string(),
                downID: z.number(),
                downloadCount: z.number(),
                downloadUrl: z.string().nullish(),
                maxDownload: z.number(),
                prodID: z.string(),
                sku: z.string(),
                slug: z.string(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                title: z.string(),
                usdoID: z.number(),
                userID: z.string(),
                variations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation
                ),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/user-downloadables/:userID',
    alias: 'downloadablesList',
    description: `List all downloadable clips by userID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'checID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'title',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_UserDownloadableView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/users',
    alias: 'userList',
    description: `Lists all users`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'deleted',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'filmmaker',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'applID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'keywords',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'email',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_UserInformation
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/users',
    alias: 'userCreate',
    description: `Create new user`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: userCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/users/:userID',
    alias: 'userDelete',
    description: `Delete one user`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/users/:userID',
    alias: 'userGet',
    description: `Get one user by userID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'patch',
    path: '/api/users/:userID',
    alias: 'userUpdate',
    description: `Update one user`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: userUpdate_Body,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/users/:userID/enable',
    alias: 'userEnable',
    description: `Enable a deleted user`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                authenticationProvider: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                roles: z.array(z.string()),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/voucher',
    alias: 'voucherList',
    description: `Lists all vouchers`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'startedAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'expiredAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'expiredAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'startedAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'code',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Voucher
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/voucher',
    alias: 'voucherCreate',
    description: `Create one voucher`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: voucherCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                code: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                expiredAt: z.string(),
                priceRules: z
                  .object({
                    discountAmount: z.string().nullable(),
                    discountPercentage: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                startedAt: z.string(),
                usageRules: z
                  .object({
                    amountOfUse: z.number(),
                    amountOfUsePerUser: z.number(),
                    onlyInCart: z.boolean(),
                  })
                  .passthrough(),
                voucID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/voucher/:voucID',
    alias: 'voucherDelete',
    description: `Delete one voucher by id`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'voucID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                code: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                expiredAt: z.string(),
                priceRules: z
                  .object({
                    discountAmount: z.string().nullable(),
                    discountPercentage: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                startedAt: z.string(),
                usageRules: z
                  .object({
                    amountOfUse: z.number(),
                    amountOfUsePerUser: z.number(),
                    onlyInCart: z.boolean(),
                  })
                  .passthrough(),
                voucID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/voucher/:voucID',
    alias: 'voucherGet',
    description: `Get one voucher by voucID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'voucID',
        type: 'Path',
        schema: z.number(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                code: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                expiredAt: z.string(),
                priceRules: z
                  .object({
                    discountAmount: z.string().nullable(),
                    discountPercentage: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                startedAt: z.string(),
                usageRules: z
                  .object({
                    amountOfUse: z.number(),
                    amountOfUsePerUser: z.number(),
                    onlyInCart: z.boolean(),
                  })
                  .passthrough(),
                voucID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/voucher/:voucID',
    alias: 'voucherUpdate',
    description: `Update voucher informations`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: voucherUpdate_Body,
      },
      {
        name: 'voucID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                code: z.string(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                expiredAt: z.string(),
                priceRules: z
                  .object({
                    discountAmount: z.string().nullable(),
                    discountPercentage: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                startedAt: z.string(),
                usageRules: z
                  .object({
                    amountOfUse: z.number(),
                    amountOfUsePerUser: z.number(),
                    onlyInCart: z.boolean(),
                  })
                  .passthrough(),
                voucID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/analytics/views/product',
    alias: 'getProductsViews',
    description: `getProductsViews`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            items: z.array(
              z.object({
                slug: z.string(),
                views: z.number(),
              })
            ),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/analytics/views/filmmakerPage',
    alias: 'getFilmmakerPageViews',
    description: `getFilmmakerPageViews`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            items: z.array(
              z.object({
                slug: z.string(),
                views: z.number(),
              })
            ),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/analytics/searchTerm',
    alias: 'listSearchTerms',
    description: `listSearchTerms`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            items: z.array(
              z.object({
                term: z.string(),
                createdAt: z.string(),
              })
            ),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/analytics/searchTerm/rank',
    alias: 'getSearchTermRank',
    description: `getSearchTermRank`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            items: z.array(
              z.object({
                term: z.string(),
                count: z.number(),
              })
            ),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/analytics/searchTerm/details',
    alias: 'listUsersBySearchTerm',
    description: `listUsersBySearchTerm`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'term',
        type: 'Query',
        schema: z.string(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            items: z.array(
              z.object({
                userID: z.string(),
                name: z.string(),
                email: z.string(),
                createdAt: z.string(),
              })
            ),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
